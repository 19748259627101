import { Link } from 'gatsby';
import React from 'react';
import { useAuth } from '../auth';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';
import { SupportContact } from '../components/support-contact';

export default () => {
  const { user } = useAuth();
  return (
    <Layout>
      <Navigation fixed />
      <div className="section">
        <div className="container is-max-desktop">
          <h2 className="subtitle">fakeOS</h2>
          <h3 className="title">Download</h3>
          <div className="columns">
            <div className="column">
              <h3 className="title">Android</h3>
              <p>
                fakeOS lässt sich auf jedem android Telefon installieren.
                Voraussetzung dafür ist das Betriebssystem Android Version 5.0
                (Lollipop) und aufwärts.
              </p>
              <p>
                Außerdem solltest du auf deinem Gerät unter „Einstellungen ➞
                Anwendungen (bei manchen Geräten unter Sicherheit) ➞ Unbekannte
                Quellen“ erlauben.
              </p>
              {!user && (
                <article className="message">
                  <div className="message-header">
                    <p>Download fakeOS (Android)</p>
                  </div>
                  <div className="message-body">
                    <p>
                      Um die fakeOS App herunterzuladen, besuche diese Seite mit
                      deinem Ziel-Gerät und{' '}
                      <Link className="has-text-primary" to="/login">
                        <strong>melde dich hier an</strong>
                      </Link>{' '}
                      oder{' '}
                      <Link className="has-text-primary" to="/register">
                        <strong>registriere dich</strong>
                      </Link>
                      , wenn du noch keinen fakeOS-Account hast!
                    </p>
                  </div>
                </article>
              )}
              {user && (
                <article className="message">
                  <div className="message-header">
                    <p>Download fakeOS (Android)</p>
                  </div>
                  <div className="message-body">
                    <p>
                      <a href="https://download.fakeos.de/latest" download>
                        <span className="icon">
                          <i className="fas fa-mobile"></i>
                        </span>
                        fakeOS (aktuelle Version)
                      </a>
                    </p>
                  </div>
                </article>
              )}
            </div>
            <div className="column has-text-centered">
              <img className="small-logo" src="images/android.png" />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <h3 className="title">iOS</h3>
              <p>
                Wenn du fakeOS oder einige Funktionen daraus speziell für dein
                iOS Gerät benötigst, tritt bitte in Kontakt mit unserem Support!
              </p>
              <SupportContact />
            </div>
            <div className="column has-text-centered">
              <img className="small-logo" src="images/ios.svg" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
