import React, { ComponentType } from 'react';
interface Props {}
export const SupportContact: ComponentType<Props> = ({}) => {
  return (
    <>
      <p>
        <strong>fakeOS</strong>
      </p>
      <p>
        E-Mail: <a href="mailto:support@fakeos.de">support@fakeos.de</a>
        <br />
        Telefon: <a href="tel:+491776170775">+49 177 6170775</a>
      </p>
    </>
  );
};
